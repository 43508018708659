import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';

import './styles/App.less';
import DrawerContent from './components/DrawerContent.js';
import MapView from './components/MapView';
import DisclaimerModal from './components/DisclaimerModal';
import Joyride from 'react-joyride';
import FloodIcon from "./styles/logo.png"; // Replace with the path to your icon


const steps = [
    {
        target: '.logo-position',
        content: <div><p>
            Welcome to Floods360! Here you can get informed about <b>Agricultural Flooded regions</b>.
        </p></div>,

        disableBeacon: true,
    },
    {
        target: '.map-container',
        content: <div>
            <p style={{ margin: 10 }}>Each <b>flood event</b> is denoted with: <span style={{ verticalAlign: 'middle' }}><img src={FloodIcon} alt="not found"  style={{ width: '100%', maxWidth: 30, marginRight: '10px' }} /></span></p>
        </div>,
        disableBeacon: true,
    },
    {
        target: '#nested-list-subheader',
        content: <div><p>
            <b>Select/Deselect</b> layer(s) for the selected flood event.
        </p></div>,
        disableBeacon: true,
    },
    {
        target: '.Disclaimer-position',
        content: <div>
            <p>
                The About Us button provides information about the <b>calculation</b> of the flooded regions and <b>Terms of Use</b>.
            </p>
        </div>,
        disableBeacon: true,
    },
];


const DrawerWidthCalculator = () => {
    const isMobile = useMediaQuery(`only screen and  (max-width: 480px)`);
    return isMobile ? 150 : 240;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => {
        const drawerWidth = DrawerWidthCalculator();
        return (
            {
                flexGrow: 1,
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                marginLeft: `-${drawerWidth}px`,
                variants: [
                    {
                        props: ({ open }) => open,
                        style: {
                            transition: theme.transitions.create('margin', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen,
                            }),
                            marginLeft: 0,
                        },
                    },
                ],
            }
        )
    },
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => {
    const drawerWidth = DrawerWidthCalculator();
    return (
        {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            variants: [
                {
                    props: ({ open }) => open,
                    style: {
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: `${drawerWidth}px`,
                        transition: theme.transitions.create(['margin', 'width'], {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    },
                },
            ],
        }
    )});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function App() {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [selectedBackground, setSelectedBackground] = useState('osm');
    const [layersData, setLayersData] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState(Array.from({ length: layersData.length }, (_, index) => index));
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
    const drawerWidth = DrawerWidthCalculator();
    const [openUniqueFloodEvents, setopenUniqueFloodEvents] = useState({});
  
    const handleFolderExpand = (floodEventKey) => {
      setopenUniqueFloodEvents({
        ...openUniqueFloodEvents, // Copy the old UniqueFloodEvents
        [floodEventKey]: !openUniqueFloodEvents?.[floodEventKey] // But override this one
      });
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLayerChange = (layer) => {
        setSelectedBackground(layer); // Update the selected layer based on button click
    };

    useEffect(() => {
        axios('/data/FloodEventList.json')
            .then((response) => {
                if (response?.data) {
                    const layersPromises = response.data.map(layerName =>
                        axios(`data/${layerName}`).then(res => ({
                            name: layerName,
                            data: res.data
                        }))
                    );

                    Promise.all(layersPromises)
                        .then((responses) => {
                            setLayersData(responses);
                            let selectedLayers = [];
                            if (responses.length > 0) {
                                for (let index = 0; index < responses.length; index++) {
                                    const layer = responses[index];
                                    if (layer?.data?.max_extend =='true') {
                                        selectedLayers.push(index);
                                    }
                                }
                                setSelectedLayers(selectedLayers);
                            }
                        });

                }
            });
    }, []);
    const handleLayerToggle = (index) => {
        setSelectedLayers(prevSelected =>
            prevSelected.includes(index)
                ? prevSelected.filter(i => i !== index)
                : [...prevSelected, index]
        );
    };

    return (
        <>
        <Joyride 
            steps={steps} 
            continuous={true}
            showProgress={true}
            showSkipButton ={true}
            locale={{
                back: 'Back',
                close: 'Close',
                last: 'End',  // Replaces "Last" with "End"
                next: 'Next',
                skip: 'Skip',}}
            styles={{
                options: {
                    zIndex: 10000,
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderRadius: '5px',
                    boxSizing: 'border-box',
                    color: 'rgb(51, 51, 51)',
                    fontSize: '16px',
                    maxWidth: '100%',
                    padding: '15px',
                    position: 'relative',
                    width: '380px',
                }
            }} 
        />
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} className="app-bar">
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                mr: 2,
                            },
                            open && { display: 'none' },
                        ]}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" className="logo-position">
                        <>
                        <img src={FloodIcon} alt="not found" style={{ width: '100%', maxWidth: 30, marginRight: '10px' }} />
                        <p style={{ margin: 0 }}>Floods360</p>
                        </>
                    </Typography>
                    <Button color="inherit" className="Disclaimer-position" onClick={() => setIsDisclaimerOpen(true)}>
                        About Us
                    </Button>
                </Toolbar>

                <div className="button-group-container">
                    <Tooltip title="Select your background" placement="bottom">
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            className="background-layer-selector"
                        >
                            <Button
                                className="background-option"
                                onClick={() => handleLayerChange('osm')}
                            >
                                OSM
                            </Button>
                            <Button
                                className="background-option"
                                onClick={() => handleLayerChange('google')}
                            >
                                Google
                            </Button>
                            {/* <Button
                                className="background-option"
                                onClick={() => handleLayerChange('landcover')}
                            >
                                Land Covers 2021
                            </Button> */}
                        </ButtonGroup>
                    </Tooltip>

                </div>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <DrawerContent
                    layersData={layersData}
                    selectedLayers={selectedLayers}
                    onLayerToggle={handleLayerToggle}
                    openUniqueFloodEvents={openUniqueFloodEvents}
                    handleFolderExpand={handleFolderExpand}
                />
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <MapView
                    selectedLayers={selectedLayers}
                    layersData={layersData}
                    selectedBackground={selectedBackground}
                    openUniqueFloodEvents={openUniqueFloodEvents}
                    handleFolderExpand={handleFolderExpand}
                />
                <DisclaimerModal isOpen={isDisclaimerOpen} onClose={() => setIsDisclaimerOpen(false)} />
            </Main>
        </Box>
        </>
        
    );
}
