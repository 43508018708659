import React, { useState } from 'react';
import { List, ListItem, ListItemText, Checkbox, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import WaterIcon from '@mui/icons-material/Water';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import formatFloodDate from './FormatDates.js'

import '../styles/DrawerContent.less';

function DrawerContent(props) {

    const [showFloodedArea, setShowFloodedArea] = useState(false);

    const handleShowFloodedArea = () => {
        setShowFloodedArea(true);
    };

    // Find the unique flood regions from all the layersData
    const uniqueFloodEvents = {}
    for (let index = 0; index < props.layersData.length; index++) {
        const layer = props.layersData[index].data;
        uniqueFloodEvents[layer.flood_event] = { flood_event: layer.flood_event, bbox: layer.bbox }
    }

    // Function to render FloodedRegions
    const renderFloodedRegions = (filteredObjectsWithIndices, open_status) => {
        return Object.entries(filteredObjectsWithIndices).map(([key, region], index) => {
            return (
                <Collapse in={open_status} timeout="auto" unmountOnExit key={index}>
                    <ListItem key={region.index} dense button={"true"} onClick={() => props.onLayerToggle(region.index)}>
                        <Checkbox
                            edge="start"
                            checked={props.selectedLayers.includes(region.index)}
                            tabIndex={-1}
                            disableRipple
                        />
                        {/* <ListItemText primary={region.data.flood_datetime_UTC} /> */}
                        <ListItemIcon>
                            {/* WaterIcon with dynamic color based on region.data.plot_color */}
                            <WaterIcon style={{ color: region.obj.data.plot_color }} />
                        </ListItemIcon>
                        <ListItemText primary={formatFloodDate(region.obj.data.flood_datetime_UTC)} />
                    </ListItem>
                </Collapse>
            )
        })
    };

    // Function to render svg with flood area over time
    const renderFloodedareaSVG = (flood_event, open_status) => {
        return (
            <Collapse in={open_status} timeout="auto" unmountOnExit>
                <Button 
                    variant="outlined"  // This makes the button outlined
                    color="inherit"     // Use inherit to avoid any theme color conflicts
                    sx={{
                        backgroundColor: 'white',    // Set the background to white for contrast with the outline
                        borderColor: 'gray',         // Set the border color to gray
                        borderWidth: 1,              // Set the border width
                        color: 'gray',               // Set the text color to gray
                        fontSize: '0.875rem',        // Make the text a bit smaller
                        padding: '6px 12px',         // Adjust padding to make the button smaller
                        width: '80%',                // Set the button width to 80% of the drawer width
                        margin: '0 auto',            // Center the button horizontally
                        display: 'block',            // Ensure the button is treated as a block element
                        '&:hover': {
                          backgroundColor: 'lightgray',  // Optional: change background color on hover
                          borderColor: 'gray',           // Keep the border gray on hover
                        },
                      }}
                    onClick={handleShowFloodedArea}>
                    {showFloodedArea ? 'Hide Flooded Area over time' : 'Show Flooded Area over time'}
                </Button>

                <Dialog open={showFloodedArea} onClose={() => setShowFloodedArea(false)} aria-labelledby="disclaimer-dialog-title">
                    <DialogTitle id="disclaimer-dialog-title"><h2>{flood_event} </h2></DialogTitle>
                    <DialogContent>
                        <img src={`../data/${flood_event}.svg`} alt="Area (km²) of flooded regions" style={{ width: '100%', maxWidth: 360 }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowFloodedArea(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Collapse>
        )
    };

    const createUniqueFloodEventfolders = () => {
        return Object.entries(uniqueFloodEvents).map(([flood_event, region], index) => {
            const filteredObjectsWithIndices = props.layersData
                .map((obj, index) => ({ obj, index }))  // Map the array to include objects and their indices
                .filter(item => item.obj.data.flood_event === flood_event);  // Filter based on the condition
            return (
                <div key={'list_item_button_container_' + index}>
                    <ListItemButton onClick={() => props.handleFolderExpand(flood_event)}>
                        <ListItemIcon>
                            <WaterIcon />
                        </ListItemIcon>
                        <ListItemText primary={flood_event} />
                        {props.openUniqueFloodEvents?.[flood_event] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    {renderFloodedRegions(filteredObjectsWithIndices, props.openUniqueFloodEvents?.[flood_event])}
                    {renderFloodedareaSVG(flood_event, props.openUniqueFloodEvents?.[flood_event])}
                </div>
            )
        })
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Flood Events
                </ListSubheader>
            }
        >
            {createUniqueFloodEventfolders()}
        </List>
    );
}
export default DrawerContent;