
// Utility function to format date from YYYYMMDDTHHMMSS to DD-Month(with letters)-YYYY HH:MM UTC
const formatFloodDate = (datetime) => {
    // Ensure datetime is in the format 'YYYYMMDDTHHMMSS'
    const datePattern = /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})$/;
    const match = datetime.match(datePattern);
  
    if (!match) {
      return 'Invalid Date';  // Return 'Invalid Date' if the format is incorrect
    }
  
    // Parse the components from the matched groups
    const year = match[1];
    const month = match[2];
    const day = match[3];
    const hour = match[4];
    const minute = match[5];
    const second = match[6];
  
    // Create a valid ISO string in the format YYYY-MM-DDTHH:MM:SSZ (UTC)
    const isoString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    const date = new Date(isoString);
  
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; // Return 'Invalid Date' if the date is invalid
    }
  
    const options = {
      day: '2-digit',
      month: 'long',  // Month as letters
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
      timeZoneName: 'short',
    };
  
    // Format date as "DD-Month-YYYY HH:MM UTC"
    return date.toLocaleString('en-GB', options).replace(' GMT', ' UTC');
  };

  export default formatFloodDate