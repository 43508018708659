
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Link, List, ListItem, ListItemText } from '@mui/material';

const DisclaimerModal = ({ isOpen, onClose }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>About Floods360</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" paragraph>
                    It provides insights into floods using analysis of Sentinel-1 satellite data, highlights agricultural (non-urban) regions affected by floods, and aids in monitoring and understanding flood dynamics. For details on the analysis, please refer to the <a href="https://github.com/kleok/FLOODPY" target="_blank" rel="noopener noreferrer">Floodpy</a> software. The goal of this application is to inform the public and organizations about historical and ongoing flood events.
                </Typography>

                <Typography variant="h6" gutterBottom>Contact us</Typography>
                <Typography variant="body1" paragraph>
                    We value your interest and are happy to assist you with any questions. For specific inquiries, technical support, additional details, or suggestions, please contact us. We also welcome your feedback if you find this website useful or if you use the website or the materials related to this research for any purposes. You can send an email to <Link href="mailto:contact@floods360.com">contact@floods360.com</Link>.
                </Typography>

                <Typography variant="h6" gutterBottom>Citation and Download</Typography>
                <Typography variant="body1" paragraph>
                    This map is provided to you under the Creative Commons Attribution 4.0 International License. If you use data from this map in publications such as journal papers, articles, presentations, posters, websites, and social media, please cite the following GitHub repo (<a href="https://github.com/kleok/FLOODPY" target="_blank" rel="noopener noreferrer">Floodpy</a>). Please also include appropriate credit to the original data provided by ESA.
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 2 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="The flood dataset contains modified Copernicus Sentinel data (dates of flood events), processed by ESA." />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>Disclaimer</Typography>
                <Typography variant="body1" paragraph>
                    The information provided on this website is intended for general informational purposes only. We do not guarantee the accuracy, completeness, reliability, or availability of the content. Users assume all responsibility for their use of the information. This website does not guarantee any results based on the use of the products, services, or information provided here. It is not meant to replace official flood notifications or reports from governmental agencies or authoritative bodies. Always consult official sources for the latest and most accurate information.
                </Typography>

                <Typography variant="h6" gutterBottom>Acknowledgment</Typography>
                <Typography variant="body1" paragraph>
                    This service utilizes various packages and data sources, including:
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 2 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="The interactive map is created using Leaflet." />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="GeoServer is used as the Web Map Service." />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="leaflet-groupedlayercontrol is utilized for grouping layers." />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="GDAL and QGIS are used for post-processing InSAR results." />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="Administrative boundaries are retrieved from OpenStreetMap®." />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="Satellite background imagery is retrieved from Google©." />
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <ListItemText primary="The map contains modified Copernicus Sentinel data 2014-present, processed by ESA." />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisclaimerModal;


// const DisclaimerModal = ({ isOpen, onClose }) => {
//   return (
//     <Dialog open={isOpen} onClose={onClose} aria-labelledby="disclaimer-dialog-title">
//       <DialogTitle id="disclaimer-dialog-title"><h2>Disclaimer</h2></DialogTitle>
//       <DialogContent>
//         <p>
//           This application provides information about <b>flooded agricultural</b> (non-urban) <b>regions</b> based on Sentinel-1 SAR data (ESA Copernicus), using the <a href="https://github.com/kleok/FLOODPY" target="_blank" rel="noopener noreferrer">Floodpy</a> software. The goal of this application is to inform the public and organizations about historical and ongoing flood events. 
//         </p>
//         <p>
//           Please note, the data displayed here is generated using automated processes and is intended for <b>informational purposes only</b>. It is not meant to replace official flood notifications or reports from governmental agencies or authoritative bodies. Always consult official sources for the latest and most accurate information.
//         </p>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default DisclaimerModal;

// // DisclaimerModal.js

// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
